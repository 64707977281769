import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { LayoutBase } from './LayoutBase';
import {
    // mobileBreakPoint,
    DrawableCanvas,
    Header,
    LayoutType,
    ChevronLeft,
    SEO
} from '../components';

// const MainHome = styled.div`
//     width: 100vw;
//     height: 100vh;
//     padding: var(--space-l);
//     overflow: hidden;

//     @media ${mobileBreakPoint} {
//         padding: var(--space-s);
//     }
// `;

export const LayoutDrawing: FC<any> = ({ children }) => (
    <LayoutBase>
        <SEO />
        <Link to="/works" style={{
            zIndex: 1,
            position: 'fixed',
            top: 'var(--space-xs)',
            left: 'var(--space-xxs)',
            width: 'var( --space-l)',
        }}>
            <ChevronLeft style={{ maxHeight: '2rem' }} />
        </Link>

        {/*<Header layoutType={LayoutType.HOME} />*/}
        <DrawableCanvas />
        {/*<MainHome >{children}</MainHome>*/}
    </LayoutBase>
);
